import React from 'react';
import Layout from '../Layout';
import "./style.scss";
import pic from "../../images/sreehari-suku.jpg";
import SEO from '../seo';

import shareicon from "../../images/volunteer-icon-3.png"
import { RWebShare } from 'react-web-share';


const GivingWingsPage = ({ location }) => {
    return (
        <Layout>
            <SEO
                title="Chinmaya Vishwa Vidyapeeth Success Stories"
                description="Read about Sreehari Suku, an alumni of Chinmaya Vishwa Vidyapeeth."
                keywords=""
                img={`https://${location.host}/link-preview.jpg`}
            />
            <div className='blog'>
                <h1>GIVING WINGS TO DREAMS</h1>

                <div className="divider"></div>
                <img className='blog__img' src={pic}></img>
                <p>
                    Hailing from Nechoor, Ernakulam, tall and lean Sreehari Suku is visibly a sportsman. His mother is a teacher and has brought up the children as a single-parent. In spite of the multitude of hurdles that confronted the family, Sreehari has determinedly continued his higher education, with the support of donors.<br /><br />
                    Sreehari enjoys reading, watching movies and TV series, listening to music, dancing, but most of all he likes playing football. He says, “I would like to become a football manager. Therefore, I'm planning to go in for an MBA in sports management.”<br /><br />
                    Currently, he is at home with his family and scouting for a job. He wants to work for two years before going abroad to pursue an MBA in Sports Management.
                    <br /><br />
                    <RWebShare data={{
                        text: "",
                        url: `https://${location.host}/get-inspired/giving-wings-to-dreams`,
                        title: "Share this article"
                    }}>
                        <button style={{ background: "transparent", border: "none", color: "#EE896D" }} className='share-btn'>
                            <img width={22} height={22} src={shareicon} style={{ transform: "scaleX(-1)" }}></img>   SHARE
                        </button>
                    </RWebShare>

                </p>
            </div>
        </Layout>
    );
}

export default GivingWingsPage;
